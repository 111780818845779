<template>
  <div
    v-for="category in categories"
    :key="category.id"
  >
    <h2>
      {{ category.name }} ({{ category.productsCount }})
    </h2>
    <Product
      v-for="(product, index) in category.products"
      :key="product.id"
      :product="product"
      :last="index === category.products.length - 1"
      @edit-product="editProduct"
    />
    <div
      class="child-categories"
      v-if="category.childCategories.length"
    >
      <div
        v-for="subCategory in category.childCategories"
        :key="subCategory.id"
      >
        <h3>
          {{ subCategory.name }} ({{ subCategory.productsCount }})
        </h3>
        <Product
          v-for="(product, index) in subCategory.products"
          :key="product.id"
          :product="product"
          :last="index === subCategory.products.length - 1"
          @edit-product="editProduct"
        />

        <div
          class="child-child-categories"
          v-if="subCategory.childCategories.length"
        >
          <div
            v-for="subSubCategory in subCategory.childCategories"
            :key="subSubCategory.id"
          >
            <h4>
              {{ subSubCategory.name }} ({{ subSubCategory.productsCount }})
            </h4>
            <Product
              v-for="(product, index) in subSubCategory.products"
              :key="product.id"
              :product="product"
              :last="index === subSubCategory.products.length - 1"
              @edit-product="editProduct"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Product from './Product.vue';

export default defineComponent({
  name: 'Categories',
  emits: ['edit-product'],
  components: {
    Product,
  },
  props: {
    categories: Array,
  },
  setup(_, { emit }) {
    const editProduct = (id) => emit('edit-product', id);

    return {
      editProduct,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/mixins.scss";

h2,
h3,
h4 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  > svg {
    font-size: 1.6rem;
    text-align: right;
    cursor: pointer;
  }
}

.child-categories {
  margin-left: 0.5rem;

  @include ipad {
    margin-left: 1rem;
  }
}

.child-child-categories {
  margin-left: 1rem;

  @include ipad {
    margin-left: 2rem;
  }
}
</style>
