<template>
  <article :class="{ last }">
    <div>
      <h5>{{ product.name }}</h5>
      {{ productPrice }} / {{ product.quantityByUnit }}
    </div>
    <div class="infos" :title="`Ce produit a été consulté ${product.views} fois`">
      <span v-if="product.views" class="infos__views">
        <FontAwesomeIcon class="infos__views__icon" :icon="['fas', 'eye']" />
        <span class="infos__views__count">{{ product.views }}</span>
      </span>
    </div>
    <div class="actions">
      <FontAwesomeIcon :icon="['fas', 'pen']" @click="editProduct(product.id)" />
    </div>
  </article>
</template>

<script>
import { defineComponent, toRefs, computed } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faPen, faEye } from '@fortawesome/free-solid-svg-icons';

library.add(faPen, faEye);

export default defineComponent({
  name: 'Product',
  components: {
    FontAwesomeIcon,
  },
  props: {
    product: Object,
    last: Boolean,
  },
  setup(props, { emit }) {
    const { product } = toRefs(props);

    const productPrice = computed(() => {
      if (product.value.price == null) {
        return '';
      }

      const stringPrice = `${product.value.price}`;
      return `${stringPrice.replace('.', ',')} €`;
    });

    const editProduct = (id) => emit('edit-product', id);

    return {
      productPrice,
      editProduct,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

article {
  background-color: $alternate-green;
  border-top: 1px solid $dark-green;
  display: flex;
  padding: 1rem;
  justify-content: space-between;

  &.last {
    border-bottom: 1px solid $dark-green;
  }

  .actions {
    display: flex;

    > * {
      align-self: center;
      cursor: pointer;
    }
  }

  .infos {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
    margin-right: 2rem;

    @include ipad {
      margin-right: 5rem;
    }

    &__views {
      font-size: 1.4rem;
      padding: 0.5rem 1.2rem;
      border-radius: 30px;
      background: $dark-green;

      @include spacing-children("horizontal", 1rem);

      @include ipad {
        padding: 0.5rem 1.5rem;
        font-size: 1.6rem;

        &__icon {
          font-size: 1.6rem;
        }
      }

      &__icon,
      &__count {
        color: $alternate-green;
      }

      &__icon {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
