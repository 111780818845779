<template>
  <div>
    <div class="selling-options">
      <label for="hasTakeAwayService" class="lowco-checkbox">
        Je propose un service de retrait
        <input
          type="checkbox"
          id="hasTakeAwayService"
          name="hasTakeAwayService"
          :checked="company.hasTakeAwayService"
          @change="onCheckChange"
        />
        <span class="checkmark"></span>
      </label>
      <label for="hasDeliveryService" class="lowco-checkbox">
        Je propose un service de livraison
        <input
          type="checkbox"
          id="hasDeliveryService"
          name="hasDeliveryService"
          :checked="company.hasDeliveryService"
          @change="onCheckChange"
        />
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="col col-12 mt-1">
      <p class="small">
        Si vous utilisez le service de retrait et/ou de livraison,
        vous recevez un email sur l’adresse renseignée à chaque commande.
        À vous d’en assurer le suivi !
      </p>
    </div>
    <div>
      <div class="col col-6 mt-1">
        <label for="clickAndCollectEmail" class="bold">Adresse e-mail de contact</label>
        <input
          type="email"
          id="clickAndCollectEmail"
          name="clickAndCollectEmail"
          :class="['lowco-textbox', { 'lowco-textbox-error': !canSubmit }]"
          :value="company.clickAndCollectEmail"
          @change="onChange"
          maxlength="254"
        />
      </div>
      <div v-if="company.hasTakeAwayService" class="col col-12 mt-1">
        <label class="bold" for="clickAndCollectInstruction">
          Instructions pour le retrait
          (mentionnez les jours de retrait possibles,
          les délais minimum entre la dernière commande et la préparation des colis…)
        </label>
        <textarea
          class="lowco-textbox mt-1"
          name="clickAndCollectInstruction"
          id="clickAndCollectInstruction"
          rows="5"
          :value="company.clickAndCollectInstruction"
          @change="onChange"
          maxlength="1000"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services',
  emits: ['company-change'],
  model: {
    prop: 'company',
    event: 'company-change',
  },
  props: {
    company: {
      type: Object,
      required: true,
      default: null,
    },
  },
  computed: {
    canSubmit() {
      const { hasDeliveryService, hasTakeAwayService } = this.company;

      if (hasDeliveryService || hasTakeAwayService) {
        return !!this.company.clickAndCollectEmail?.trim();
      }

      return true;
    },
  },
  methods: {
    onChange(event) {
      const { company } = this;
      const { name, value } = event.target;

      const changedCompany = {
        ...company,
        [name]: value,
      };

      this.$emit('company-change', changedCompany);
    },
    onCheckChange(event) {
      const { company } = this;
      const { name, checked } = event.target;

      const changedCompany = {
        ...company,
        [name]: checked,
      };

      this.$emit('company-change', changedCompany);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/mixins.scss";

.col {
  padding: 0;
}

.websites {
  @include spacing-children("vertical", 1rem);

  @include ipad {
    display: flex;

    @include spacing-children("vertical", 0);
    @include spacing-children("horizontal", 1rem);
    @include evenly-divided(2, 1rem);
  }
}

.small {
  font-size: 1.3rem;
}

.lowco-checkbox {
  margin-top: 1rem;
  margin-left: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: -200%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.selling-options {
  margin-left: 2rem;

  @include ipad {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include spacing-children("horizontal", 4rem);
  }
}
</style>
