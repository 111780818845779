<template>
  <Layout :loading="isLoading">
    <a class="link" href="https://my-dev.heureca.eu/" rel="nofollow noopener noreferrer">Gérer mes produits</a>
    <div class="top">
      <div
        v-if="successfullySaved"
        class="lowco-textbox lowco-textbox-success"
      >Sauvegarde effectuée avec succès</div>
      <p class="tac">
        Créez des articles et assignez-les à
        une catégorie pour faciliter la recherche des utilisateurs.
      </p>

      <div class="blank-area" />
      <div class="button">
        <Button text="Ajouter un article" @buttonClick="addProduct" extraSmall />
      </div>

      <Accordion :defaultDisplayContent="!isServicesClosed" v-if="company" title="Services">
        <Services :company="company" @company-change="company = $event" />

        <div class="is-right mt-1">
          <Button
            :disabled="!canSubmit"
            class="save-button"
            text="Sauvegarder"
            @buttonClick="saveCompany"
            extraSmall
          />
        </div>
      </Accordion>
    </div>

    <AddProduct
      :categories="categories"
      :editProduct="product"
      :companyId="companyId"
      :isDisplayed="isDisplayedProduct"
      @close-modal-product="closeModalProduct"
      @delete="deleteProduct"
      @on-media-delete="onProductMediaDelete"
    />

    <Categories
      v-if="computedFilteredCategories"
      :categories="computedFilteredCategories"
      @edit-product="editProduct"
    />
  </Layout>
</template>

<script>
import { mapState } from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen } from '@fortawesome/free-solid-svg-icons';

import Layout from '@/components/common/Layout.vue';
import Button from '@/components/common/Button.vue';
import AddProduct from '@/components/catalogue/AddProduct.vue';
import Categories from '@/components/catalogue/Categories.vue';
import Services from '@/components/profile/Services.vue';
import Accordion from '@/components/common/Accordion.vue';
import lowcoApi from '@/api/lowco-api';

library.add(faPen);

export default {
  name: 'Catalog',
  components: {
    Layout,
    Button,
    AddProduct,
    Categories,
    Services,
    Accordion,
  },
  data() {
    return {
      isLoading: false,
      isDisplayedProduct: false,
      product: null,
      products: [],
      categories: [],
      productViews: [],
      company: null,
      successfullySaved: false,
    };
  },
  computed: {
    ...mapState({
      companyId: (state) => state.company.id,
    }),
    computedCategories() {
      return [
        this.mapCategory({ name: 'Sans catégorie', childCategories: [], readonly: true }),
        ...this.categories,
      ].map(this.mapCat);
    },
    computedFilteredCategories() {
      return this.filterCategories(this.computedCategories);
    },
    productViewsObject() {
      return this.productViews.length
        ? this.productViews.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.viewCount }), {})
        : [];
    },
    productsWithViews() {
      return this.products.map((p) => ({ ...p, views: this.productViewsObject?.[p.id] || null }));
    },
    isServicesClosed() {
      const { hasTakeAwayService, hasDeliveryService, clickAndCollectEmail } = this.company;

      return (hasTakeAwayService || hasDeliveryService) && clickAndCollectEmail;
    },
    canSubmit() {
      const { hasDeliveryService, hasTakeAwayService } = this.company;

      if (hasDeliveryService || hasTakeAwayService) {
        return !!this.company.clickAndCollectEmail?.trim();
      }

      return true;
    },
  },
  watch: {
    companyId(value) {
      if (!value) {
        return;
      }

      this.loadCompany();
      this.loadData();
      this.loadProductsViews();
    },

    successfullySaved(value) {
      if (!value) {
        return;
      }

      setTimeout(() => {
        this.successfullySaved = false;
      }, 3000);
    },
  },
  methods: {
    filterCategories(categories) {
      const filtered = categories.filter((c) => c.productsCount);

      return filtered.map((c) => ({
        ...c,
        childCategories: this.filterCategories(c.childCategories),
      }));
    },
    mapCat(category) {
      const c = this.mapCategory(category);
      c.productsCount = c.products.length;

      if (c.childCategories.length) {
        c.childCategories = c.childCategories.map(this.mapCat);
        c.productsCount += c.childCategories.reduce(
          (acc, curr) => acc + curr.productsCount,
          0,
        );
      }
      return c;
    },
    addProduct() {
      this.isDisplayedProduct = true;
    },
    closeModalProduct(product) {
      const isNew = !this.product;
      this.product = null;
      this.isDisplayedProduct = false;

      if (!product) {
        return;
      }

      if (isNew) {
        this.products = [...this.products, product];
      } else {
        this.products = this.products.map((p) => (p.id !== product.id ? p : product));
      }
    },
    editProduct(productId) {
      this.product = this.productsWithViews.find((i) => i.id === productId);
      this.isDisplayedProduct = true;
    },
    mapCategory(category) {
      return {
        ...category,
        products: this.productsWithViews.filter((i) => i.categoryId === category.id),
      };
    },
    async loadCompany() {
      try {
        this.isLoading = true;
        const company = await lowcoApi.getMyEnterprise(this.companyId);
        this.company = company;
      } catch (err) {
        // Handle error
      } finally {
        this.isLoading = false;
      }
    },
    async loadData() {
      try {
        this.isLoading = true;
        const categories = await lowcoApi.getProductCategories();
        const products = await lowcoApi.getProducts(this.companyId);

        this.categories = categories;
        this.products = products;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteProduct(productId) {
      try {
        await lowcoApi.deleteProduct(productId);

        this.closeModalProduct(null);

        this.products = this.products.filter((p) => p.id !== productId);
      } catch (err) {
        console.log(err);
      }
    },
    async onProductMediaDelete(productId, mediaId) {
      try {
        await lowcoApi.removeProductMedia(productId, mediaId);

        this.product = {
          ...this.product,
          medias: this.product.medias.filter((m) => m.id !== mediaId),
        };
      } catch (err) {
        console.log(err);
      }
    },
    async loadProductsViews() {
      try {
        const result = await lowcoApi.getProductsViews(this.companyId);

        if (!result) {
          this.productViews = [];
          return;
        }

        this.productViews = result;
      } catch (err) {
        this.productViews = [];
      }
    },
    async saveCompany() {
      try {
        await lowcoApi.updateCompany(this.company);

        this.successfullySaved = true;
      } catch (err) {
        // Handle error
      }
    },
  },
  mounted() {
    this.loadCompany();
    this.loadData();
    this.loadProductsViews();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/variables.scss";
@import "@/assets/styles/common/mixins.scss";

.is-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top {
  @include spacing-children("vertical", 1rem);

  .blank-area {
    position: fixed;
    z-index: 99999;
    inset: auto 0 calc(6rem + env(safe-area-inset-bottom)) 0;
    background-color: #fff;
    height: 6rem;
    box-shadow: 0 -2px 10px rgba(#000, .15);
  }

  .button {
    z-index: 100000;
    position: fixed;
    bottom: 7rem;
    bottom: calc(7rem + env(safe-area-inset-bottom));
    left: calc(2rem + env(safe-area-inset-left));
    right: calc(2rem + env(safe-area-inset-right));

    button {
      width: 100%;
    }
  }

  @include ipad {

    .blank-area {
      display: none;
    }
    .button {
      position: static;
      display: flex;
      justify-content: center;

      button {
        width: auto;
      }
    }
  }
}

.save-button {
  width: auto;
}

.buttons {
  text-align: right;

  > div {
    display: inline-block;
    max-width: 330px;
    margin: 10px;
  }
}
</style>
