<template>
  <Modal :isDisplayed="isDisplayed" :isFreezed="isLoading" @closeModal="closeModal">
    <div v-show="!addImageSection">
      <h3>Ajouter un article</h3>
      <div>
        <div class="col col-6 mt-1">
          <label for="name">
            Nom
            <span class="attention-star">*</span>
          </label>
          <input type="text" class="lowco-textbox" v-model="product.name" required />
        </div>
        <div class="col col-6 mt-1">
          <label class="bold" for="category">Catégorie</label>
          <select
            name="category"
            id="category"
            class="lowco-textbox"
            v-model="product.categoryId"
            required
          >
            <option
              v-for="category in styledCategories"
              :key="category.id"
              :value="category.id"
              v-html="category.name"
            />
          </select>
        </div>
      </div>
      <div>
        <div class="col col-6 mt-1">
          <label for="name">
            Prix
            <span class="attention-star">*</span>
          </label>
          <input
            required
            type="text"
            class="lowco-textbox"
            placeholder="5 €"
            :value="productPrice"
            @change="onProductPriceChange"
          />
        </div>
        <div class="col col-6 mt-1">
          <label for="name">
            Quantité par unité de mesure
            <span class="attention-star">*</span>
          </label>
          <input
            required
            type="text"
            class="lowco-textbox"
            placeholder="9 ml"
            v-model="product.quantityByUnit"
          />
        </div>
      </div>
      <div>
        <div class="col col-12 mt-1">
          <label for>Description</label>
          <ckeditor :editor="editor" :config="editorConfig" v-model="product.description" />
        </div>
      </div>
      <div>
        <div class="col col-12 mt-1">
          <label for>Images</label>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            ref="file"
            class="hidden"
            v-on:input="loadImage"
          />
          <div class="col col-12 images">
            <div class="images__item" v-for="image in temporaryImageList" :key="image.id">
              <span class="images__item__times" @click="onTimesClick(image)">
                <FontAwesomeIcon :icon="['fal', 'times']" />
              </span>
              <ResponsiveImage :image="image.mediaUrl" is4by3 />
            </div>
          </div>
          <p class="mt-1 mb-1 tac">
            Sélectionnez une image
            <strong>horizontale</strong> de max 2Mo.
          </p>
          <Button
            class="add-image"
            :text="'Ajouter une image'"
            :disabled="cannotAddImage"
            alternate
            extraSmall
            @buttonClick="addImage"
          />

          <MessageBanner class="warning-msg" v-if="!temporaryImageList.length">
            <p>Vous devez ajouter au moins une image.</p>
          </MessageBanner>

          <MessageBanner class="warning-msg" v-if="cannotAddImage">
            <p>Le nombre maximal d'images est de 5.</p>
            <p>
              Veuillez supprimer une ou des image(s) existante(s) afin
              d'en ajouter de nouvelles.
            </p>
          </MessageBanner>
        </div>
      </div>
      <div class="mt-3 mb-1">
        <h2 class="tac">Vidéos du produit</h2>
        <Videos
          :videos="temporaryVideoList"
          @on-video-added="addVideo"
          @on-video-deleted="deleteVideo"
        />
      </div>
      <div v-if="productSaveSuccessfully" class="lowco-textbox lowco-textbox-success mt-2">
        <p>Le produit a été sauvegardé avec succès !</p>
        <p v-if="imagesToAdd.length">Sauvegarde des images en cours...</p>
        <p v-if="videosToAdd.length">Sauvegarde des vidéos en cours...</p>
      </div>
      <div class="errors" v-if="errors.length">
        <div
          v-for="error in errors"
          :key="error"
          class="lowco-textbox lowco-textbox-error mt-1"
        >{{ error }}</div>
      </div>
      <p class="mt-2">
        <span class="attention-star">*</span>
        Champs obligatoires
      </p>
      <div class="buttons mt-2">
        <Button
          v-if="product.id && !isLoading"
          text="Supprimer"
          @buttonClick="deleteProduct(product.id)"
          isDanger
          extraSmall
        />

        <Button
          :text="buttonText"
          @buttonClick="save"
          extraSmall
          :disabled="isFormNotValid"
          :isLoading="isLoading"
        />
      </div>
    </div>

    <ImageCropper v-show="addImageSection" :image-source="imgSrc" @change="saveImage">
      <template v-slot:header>
        <h4>Ajouter une image</h4>
        <p class="mb-1">Veuillez définir votre image.</p>
        <p>
          Sélectionnez une image
          <strong>horizontale</strong> de max 2Mo.
        </p>
        <p class="mb-2">
          Si vous sélectionnez du vide de part et d'autre d'une image verticale,
          il sera comptabilisé dans la taille totale de l'image affichée.
        </p>
      </template>
    </ImageCropper>
  </Modal>
  <Modal :isDisplayed="isModalConfirmationActive" @close-modal="toggleConfirmationModal">
    <p>Êtes-vous sûr de vouloir supprimer cette image ?</p>

    <div class="buttons mt-2">
      <Button text="Supprimer" isDanger extraSmall @buttonClick="onDeleteImage" />
      <Button text="Annuler" isGrey extraSmall @buttonClick="toggleConfirmationModal" />
    </div>
  </Modal>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import stringUtils from '@/utils/string.utils';
import lowcoApi from '@/api/lowco-api';
import mediaTypes from '@/constants/mediaTypes.constants';

import Modal from '../common/Modal.vue';
import Button from '../common/Button.vue';
import ResponsiveImage from '../common/ResponsiveImage.vue';
import MessageBanner from '../common/MessageBanner.vue';
import Videos from '../profile/Videos.vue';
import ImageCropper from '../common/ImageCropper.vue';

library.add(faTimes);

export default {
  name: 'AddCategory',
  components: {
    Button,
    Modal,
    ResponsiveImage,
    FontAwesomeIcon,
    Videos,
    MessageBanner,
    ImageCropper,
  },
  props: {
    isDisplayed: Boolean,
    editProduct: Object,
    categories: Array,
    companyId: String,
  },
  emits: ['close-modal-product', 'delete', 'on-media-delete'],
  data() {
    return {
      addImageSection: false,
      imgSrc: '../assets/logo-lowco.png',
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'bold',
          'italic',
          'numberedList',
          'bulletedList',
          '|',
          'link',
          '|',
          'undo',
          'redo',
        ],
      },
      product: null,
      imagesToAdd: [],
      currentFileName: 'blob.jpeg',
      isModalConfirmationActive: false,
      imageToDelete: null,
      temporaryImageList: [],
      isLoading: false,
      errors: [],
      productSaveSuccessfully: false,
      videosToAdd: [],
      temporaryVideoList: [],
    };
  },
  computed: {
    styledCategories() {
      const list = [];

      if (this.categories) {
        this.categories
          .filter((c) => c.id)
          .forEach((category) => {
            list.push({
              id: category.id,
              name: category.name,
            });

            category.childCategories.forEach((subCategory) => {
              list.push({
                id: subCategory.id,
                name: `&nbsp;&nbsp;${subCategory.name}`,
              });

              subCategory.childCategories.forEach((ssc) => {
                list.push({
                  id: ssc.id,
                  name: `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${ssc.name}`,
                });
              });
            });
          });
      }

      return list;
    },
    buttonText() {
      return this.product.id ? 'Sauvegarder' : 'Ajouter';
    },
    productVideos() {
      return this.product.medias.filter((m) => m.mediaType === mediaTypes.VIDEO);
    },
    cannotAddImage() {
      return this.temporaryImageList.length >= 5;
    },
    productPrice() {
      if (this.product.price == null) {
        return '';
      }

      const stringPrice = `${this.product.price}`;
      const formattedPrice = stringPrice.replace(/\./, ',');

      return formattedPrice;
    },
    isFormNotValid() {
      return !this.product.name
        || this.product.price == null
        || this.product.price === undefined
        || `${this.product.price}`.trim() === ''
        || this.product.quantityByUnit === null
        || this.product.quantityByUnit === undefined
        || `${this.product.quantityByUnit}`.trim() === ''
        || !this.temporaryImageList.length;
    },
  },
  watch: {
    isDisplayed(newVal) {
      if (newVal) {
        this.createLocalProduct();
      } else {
        this.imagesToAdd = [];
        this.videosToAdd = [];
      }
    },
    editProduct() {
      this.createLocalProduct();
    },
    isModalConfirmationActive(value) {
      if (!value) {
        this.imageToDelete = null;
      }
    },
    addImageSection(value) {
      if (!value) {
        this.$refs.file.value = '';
      }
    },
  },
  methods: {
    createLocalProduct() {
      if (this.editProduct) {
        this.product = {
          id: this.editProduct.id,
          name: this.editProduct.name,
          categoryId: this.editProduct.categoryId,
          price: this.editProduct.price,
          quantityByUnit: this.editProduct.quantityByUnit,
          description: this.editProduct.description,
          medias: this.editProduct.medias,
        };
      } else {
        this.product = {
          id: 0,
          name: '',
          categoryId: this.styledCategories[0].id,
          price: '',
          quantityByUnit: '',
          description: '',
          medias: [],
        };
      }

      const imagesToAddLeftovers = this.imagesToAdd.map(({ id, mediaUrl }) => ({ id, mediaUrl }));

      this.temporaryImageList = [
        ...this.product.medias.filter((m) => m.mediaType === mediaTypes.IMAGE),
        ...imagesToAddLeftovers,
      ];

      const videosToAddLeftovers = this.videosToAdd.map(({ id, mediaUrl }) => ({ id, mediaUrl }));

      this.temporaryVideoList = [
        ...this.product.medias.filter((m) => m.mediaType === mediaTypes.VIDEO),
        ...videosToAddLeftovers,
      ];
    },
    closeModal() {
      if (this.addImageSection) {
        this.addImageSection = false;
      } else {
        this.$emit('close-modal-product');
      }
    },
    addImage() {
      this.$refs.file.click();
    },
    loadImage(e) {
      const file = e.target.files[0];

      this.errors = [];

      const fileNameRegExp = new RegExp(/(\.png|jpeg|jpg|svg)$/, 'i');
      this.currentFileName = file.name;

      if (!fileNameRegExp.test(file.name)) {
        this.errors = [
          ...this.errors,
          'Le fichier doit être une image du format JPEG/JPG/PNG/SVG',
        ];

        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrc = event.target.result;
      };
      reader.readAsDataURL(file);

      this.addImageSection = true;
    },
    saveImage(result) {
      if (!this.addImageSection) {
        return;
      }

      const { image, errors } = result;

      if (errors?.length) {
        this.errors = [
          ...this.errors,
          ...errors,
        ];

        this.addImageSection = false;

        return;
      }

      this.imagesToAdd = [...this.imagesToAdd, {
        ...image,
        name: this.currentFileName,
      }];

      this.temporaryImageList = [
        ...this.temporaryImageList,
        {
          id: image.id,
          mediaUrl: image.mediaUrl,
        },
      ];

      this.addImageSection = false;
    },
    async save() {
      try {
        this.errors = [];
        this.isLoading = true;
        let addedProduct;
        let price;

        if (typeof this.product.price === 'number') {
          price = this.product.price;
        } else {
          price = this.product.price.replace(/,/, '.');
        }

        const newProduct = {
          name: this.product.name,
          price: parseFloat(price),
          companyId: this.companyId,
          quantityByUnit: this.product.quantityByUnit,
          categoryId: this.product.categoryId,
          description: this.product.description,
        };

        if (!this.product.id) {
          addedProduct = await lowcoApi.addProduct(newProduct);
        } else {
          addedProduct = await lowcoApi.updateProduct({
            id: this.product.id,
            ...newProduct,
          });
        }

        this.productSaveSuccessfully = true;

        if (!this.imagesToAdd.length && !this.videosToAdd.length) {
          this.isLoading = false;
          this.productSaveSuccessfully = false;

          this.$emit('close-modal-product', this.product);
        }

        const imagePromises = this.imagesToAdd.map(({ id, name, blob }) => new Promise(
          (resolve, reject) => {
            (async () => {
              try {
                const formData = new FormData();

                formData.append('Files', blob, name);

                const result = await lowcoApi.addImageToProduct(addedProduct.id, formData);
                resolve(result);
              } catch (err) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ error: err, imageId: id, type: mediaTypes.IMAGE });
              }
            })();
          },
        ));

        const videoPromises = this.videosToAdd.map((video) => new Promise(
          (resolve, reject) => {
            (async () => {
              try {
                const result = await lowcoApi.addVideoToProduct(addedProduct.id, video);
                resolve(result);
              } catch (err) {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({ error: err, id: video.id, type: mediaTypes.VIDEO });
              }
            })();
          },
        ));

        Promise
          .all([...imagePromises, ...videoPromises])
          .then((products) => {
            const lastAddedProduct = products[products.length - 1];
            this.isLoading = false;
            this.productSaveSuccessfully = false;
            this.imagesToAdd = [];
            this.videosToAdd = [];

            this.$emit('close-modal-product', lastAddedProduct);
          })
          .catch((err) => {
            if (err.type === mediaTypes.IMAGE) {
              this.imagesToAdd = this.imagesToAdd.filter((i) => i.id !== err.id);
              this.temporaryImageList = this.temporaryImageList.filter((i) => i.id !== err.id);

              this.errors = [
                ...this.errors,
                'Une erreur s\'est produite lors de l\'ajout de l\'image, vérifiez que celle-ci est bien dans le format JPEG/PNG et n\'excède pas les 2MO en taille.',
              ];
            } else if (err.type === mediaTypes.VIDEO) {
              this.videosToAdd = this.videosToAdd.filter((v) => v.id !== err.id);
              this.temporaryVideoList = this.temporaryVideoList.filter((v) => v.id !== err.id);

              this.errors = [
                ...this.errors,
                'Une erreur s\'est produite lors de l\'ajout de la vidéo, vérifiez que lien de celle-ci est valable et pointe bel et bien vers une vidéo YouTube',
              ];
            }
          });
      } catch (err) {
        this.errors = [
          ...this.errors,
          'Une erreur est survenue lors de la sauvegarde de votre produit. Veuillez réessayer ultérieurement.',
        ];
        this.isLoading = false;
      } finally {
        this.errors = [];
      }
    },
    deleteProduct(productId) {
      this.$emit('delete', productId);
    },
    toggleConfirmationModal() {
      this.isModalConfirmationActive = !this.isModalConfirmationActive;
    },
    onTimesClick(image) {
      this.toggleConfirmationModal();
      this.imageToDelete = image;
    },
    onDeleteImage() {
      const localImage = this.imagesToAdd.find((i) => i.id === this.imageToDelete.id);
      if (localImage) {
        this.imagesToAdd = this.imagesToAdd.filter((i) => i.id !== this.imageToDelete.id);
        this.temporaryImageList = this.temporaryImageList
          .filter((i) => i.id !== this.imageToDelete.id);
        this.toggleConfirmationModal();

        return;
      }

      this.$emit('on-media-delete', this.product.id, this.imageToDelete.id);
      this.toggleConfirmationModal();
    },
    onProductPriceChange(event) {
      const price = event.target.value;
      const validPrice = price.replace(/,/, '.');

      const numericPrice = parseFloat(validPrice);

      if (Number.isNaN(numericPrice)) {
        this.product = {
          ...this.product,
          price: null,
        };
        return;
      }

      this.product = {
        ...this.product,
        price,
      };
    },
    addVideo(video) {
      const randomId = stringUtils.randomString();

      this.videosToAdd = [
        ...this.videosToAdd,
        {
          id: randomId,
          ...video,
        },
      ];

      this.temporaryVideoList = [
        ...this.temporaryVideoList,
        {
          id: randomId,
          mediaUrl: video.url,
        },
      ];
    },
    async deleteVideo(videoId) {
      const localVideo = this.videosToAdd.find((v) => v.id === videoId);

      if (localVideo) {
        this.videosToAdd = this.videosToAdd.filter((v) => v.id !== localVideo.id);
        this.temporaryVideoList = this.temporaryVideoList.filter((v) => v.id !== localVideo.id);

        return;
      }

      this.$emit('on-media-delete', this.product.id, videoId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

select.lowco-textbox {
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 1rem;
  width: 100%;
  height: 41px;

  &:focus {
    border-color: #00de9a;
    outline: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
}

label {
  margin-left: 0.3rem;
  margin-bottom: 0.5rem;
}

.add-image {
  display: flex;
  justify-content: center;
}

.images {
  padding: 2rem 0;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 2rem;
  justify-content: center;

  &__item {
    position: relative;

    &__times {
      background-color: $dark-green;
      color: #fff;
      font-size: 1.2rem;
      padding: 0 0.6rem;
      border-radius: 50%;

      position: absolute;
      z-index: 9;
      top: 0;
      right: 0;

      transform: translate(40%, -40%);
      cursor: pointer;
    }
  }
}

.buttons {
  @include spacing-children("vertical", 1rem);

  @include ipad {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include spacing-children("vertical", 0);
    @include spacing-children("horizontal", 2rem);
  }
}

.attention-star {
  color: $error;
}

.warning-msg {
  margin-top: 2rem;
}
</style>
